<template>
  <el-dialog
    title="库存详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="100%"
    fullscreen
    center
  >
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form ref="addOutboundForm" :model="outboundForm" label-width="100px">
      <div>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="SKU" prop="outboundCode">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Style" prop="outboundCode">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Size" prop="outboundCode">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="类别">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="BU">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="承诺库存">
              {{ outboundForm.outboundCode }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
      <!-- <span>
        <el-button type="primary" :disabled="show" :loading="releaseLoading">
          {{ $t('title.Shortagecompletion') }}</el-button>
        <el-button>{{ $t('page.export') }}</el-button>
      </span> -->
    </el-row>
    <el-form ref="addOutboundForm" :model="outboundForm" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="逻辑仓" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="货主" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <!-- <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="info"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="value" label="逻辑仓库" width="180" align="center" />
      <el-table-column prop="value" label="货主" width="180" align="center" />
      <el-table-column prop="cartonVolume" label="承诺库存" width="120" align="center">
        <template slot-scope="scope"><router-link :to="{ path: 'inventory-transit'}">{{ scope.row.value }}</router-link></template>
      </el-table-column>
    </el-table> -->
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
// import PlatSelect from '@/components/ListSelection/PlatList'
// import PlatformSelect from '@/components/ListSelection/platform'

export default {
  name: 'InventoryDetails',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    info: {
      type: Array,
      default: () => ([])
    }

  },
  data() {
    return {
      headlineProductsList: [],
      outboundForm: {
        outboundCode: '543656345',
        productType: '',
        productCode: '',
        inspectionWarehouse: '',
        targetWarehouseName: '',
        sourceCode: '',
        outboundStatus: '0', // 出库状态
        outboundType: '',
        outboundGoodsPlatformId: '',
        sendSellPlatformId: '',
        sendSiteCode: '',
        outPairs: 0, // 应出库数 新增不需要
        sendBoxNumber: 0, // 应出库箱数 新增不需要
        deliveryTime: '', // 预计发货时间
        remark: '',
        outboundWarehouseId: '' // 当前仓库id
      },
      tableLoading: false,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }]

      // rules: {
      //   depositType: [{ required: true, message: this.$t('title.selectdeliverytype'), trigger: 'change' }],
      //   receiveType: [{ required: true, message: this.$t('title.selectdeliverymethod'), trigger: 'change' }]
      // }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  // watch: {
  //   'visible'(val) {
  //     if (val) {
  //       this.$nextTick(() => {
  //         this.$print(this.$refs.printWalmart)
  //       })
  //       // this.$nextTick(() => {
  //       //   this.$emit('input', false)
  //       // })
  //     }
  //   }
  // },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
